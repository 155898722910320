<template>
    <div class="storeDecorate-container">
        <div v-swiper:mySwiper="bannerOption" class="computer-banner">
            <div class="swiper-wrapper">
                <div class="swiper-slide" :key="bannerItem.id" v-for="bannerItem in bannerList">
                    <img :src="bannerItem ? bannerItem : require('../../../../assets/images/noimg_banner.png')" class="banner-img">
                </div>
                <div class="swiper-slide" v-if="bannerList.length === 0">
                    <div class="no-img">
                        <img :src="require('../../../../assets/images/noimg_banner.png')" class="banner-img">
                        <span style="color: #999999;margin-top: 10px;">还未上传海报,点击上传</span>
                    </div>
                </div>
            </div>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="banner-swiper-btn-prev">
                <i class="iconfont">&#xe680;</i>
            </div>
            <div class="banner-swiper-btn-next">
                <i class="iconfont">&#xe602;</i>
            </div>
        </div>
        <div class="banner-form">
            <div class="banner-item" v-for="(item, index) in bannerBtns">
                <div class="upload_banner">
                    <i class="iconfont" @click="deleImg(index)" v-if="item !== require('../../../../assets/images/noimg.png')">&#xe668;</i>
                    <img :src="item" alt="">
                </div>
                <el-button icon="el-icon-upload2" style="width: 100%; margin-top: 20px" type="primary" plain @click="clickBanner(index)">
                    点击上传
                </el-button>
            </div>
            <input type="file" name="bannerItem" id="bannerItem" ref="bannerItem" style="display: none;" @change="uploadBanner()">
        </div>
        <div class="storeSign-prompt">
            <i class="iconfont">&#xe72a;</i>
            <span class="title">提示</span>上传尺寸为1920px*630px，仅支持jpg、jpeg、png格式,每张图片小于等于2M
        </div>
        <div style="height: 1%; flex: 1"></div>
        <div class="storeSign-btn">
            <el-button type="primary" @click="submitBtn()">提交</el-button>
        </div>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import _ from "underscore";
    import { stuOpDzPosterUploadImg, stuOpSaveDzAndPposter, stuOpDzPosterList } from '@/utils/apis.js'

    export default {
        name: "ComputerPoster",
        data() {
            return {
                //轮播图
                bannerList: [],
                bannerTmpList: [null, null, null, null, null],
                bannerOption: {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false
                    },
                    initialSlide: 1,
                    autoHeight: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.banner-swiper-btn-next',
                        prevEl: '.banner-swiper-btn-prev',
                    },
                    observer: true,
                    observeParents:true,
                },
                //上传图片列表
                bannerBtns: [
                    require('../../../../assets/images/noimg.png'),
                    require('../../../../assets/images/noimg.png'),
                    require('../../../../assets/images/noimg.png'),
                    require('../../../../assets/images/noimg.png'),
                    require('../../../../assets/images/noimg.png')
                ],
                bannerSave: [],
                bannerSaveTmp: [null,null,null,null,null],
                uploadIndex: null,
                bannerId: null
            }
        },
        components: {
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        mounted() {
            this.getBannerList();
        },
        methods: {
            //获取banner
            getBannerList() {
                stuOpDzPosterList({type: 2}).then((res) => {
                    if (res.data.url.length > 0) {
                        let len = res.data.url.length;
                        this.bannerBtns.splice(0, len);
                        this.bannerBtns = res.data.url.concat(this.bannerBtns);
                        this.bannerSaveTmp = res.data.url.concat(this.bannerSaveTmp.splice(0, len));
                        this.bannerSave = this.filterNull(this.bannerSaveTmp);
                        this.bannerTmpList = res.data.url.concat(this.bannerTmpList.splice(0, len));
                        this.bannerList = this.filterNull(this.bannerTmpList);
                    }
                }).catch((err) => {})
            },
            // end 上传图片
            deleImg(index){
                this.bannerSave.splice(index,1);
                this.bannerSaveTmp.splice(index,1);
                this.bannerTmpList.splice(index,1);
                this.bannerBtns.splice(index,1);
                this.bannerBtns.push(require('../../../../assets/images/noimg.png'))
                this.bannerList.splice(index,1);
            },
            //提交
            submitBtn() {
                if (this.bannerSave.length === 0) {
                    this.$message.warning('请上传海报')
                    return false
                }
                let params = {
                    type: 2,
                    url: this.bannerSave
                }

                let formData = new FormData()
                formData.append('type', '2')
                formData.append('url', this.bannerSave)

                stuOpSaveDzAndPposter(params).then((res) => {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            window.location.reload();
                            this.getBannerList();
                        }
                    })
                }).catch((err) => {})
            },
            //调出input
            clickBanner(index){
                this.$refs.bannerItem.click();
                this.uploadIndex = index;
            },
            //上传
            uploadBanner(){
                let file = this.$refs.bannerItem.files[0];

                if (file) {
                    const isImg = file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png');
                    const isLt2M = file.size / 1024 / 1024 < 2;
                    if (!isImg) {
                        this.$message.error('上传图片只能是 jpg 或 jpeg 或 png 格式!');
                        return false;
                    }
                    if (!isLt2M) {
                        this.$message.error('上传图片大小不能超过 2MB!');
                        return false;
                    }
                }

                let formData = new FormData();
                formData.append('image', file);
                formData.append('type', '2');
                stuOpDzPosterUploadImg(formData).then((res) => {
                    this.bannerBtns.splice(this.uploadIndex, 1, res.data);
                    this.bannerTmpList.splice(this.uploadIndex, 1, res.data);
                    this.bannerSaveTmp.splice(this.uploadIndex, 1, res.data);
                    this.bannerList = this.filterNull(this.bannerTmpList);
                    this.bannerSave = this.filterNull(this.bannerSaveTmp);
                    this.$message.success(res.msg)
                }).catch((err) => {

                }).finally(() => {
                    this.$refs.bannerItem.value = '';
                })
            },
            //去除null
            filterNull(arr) {
                let array = _.filter(arr, function (item) {
                    return item !== null;
                });
                return array;
            }
        }
    }
</script>

<style scoped lang="scss">
    .storeDecorate-container {
        display: flex;
        flex-direction: column;
        background: #fff;
        min-height: calc(100vh - 120px);
        box-sizing: border-box;
    }
    .computer-banner {
        width: 100%;
        /*height: 436px;*/
        height: 395px;
        .banner-img {
            display: block;
            /*width: calc(100% - 180px);*/
            /*max-height: 630px;*/
            max-width: 100%;
            max-height: 100%;
            margin: 29px auto 0;
        }
        ::v-deep .swiper-wrapper {
            height: calc(100% - 20px) !important;
            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                height: calc(100% - 20px) !important;
            }
        }
        ::v-deep .swiper-pagination {
            position: relative;
            bottom: 0;
        }
        ::v-deep .swiper-pagination-bullets {
            .swiper-pagination-bullet {
                width: 30px;
                height: 3px;
                background: #2C405F;
                border-radius: 0;
            }
        }
        .no-img {
            width: 264px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .banner-swiper-btn-prev,
        .banner-swiper-btn-next {
            width: 90px;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            opacity: .5;
            cursor: pointer;
            img {
                display: block;
                width: 20px;
            }
            &:hover {
                opacity: 1;
            }
        }
        .banner-swiper-btn-prev {
            left: 0;
            i {
                color: #BFBFBF;
                font-size: 30px;
            }
        }
        .banner-swiper-btn-next {
            right: 0;
            i {
                color: #BFBFBF;
                font-size: 34px;
            }
        }
    }
    .banner-form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 90px 22px;
        .banner-item {
            width: calc(20% - 20px);
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
        .upload_banner {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 82px;
            background-color: #FAFAFA;
            i{
                position: absolute;
                right: -5px;
                top: -5px;
                color: #d81e06;
                font-size: 12px;
                cursor: pointer;
            }
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .upload-demo {
            width: 100%;
            ::v-deep .el-upload {
                width: 100%;
            }
        }
    }
    .storeSign-prompt {
        color: #999;
        margin: 0 90px 0;
        min-height: 45px;
        border: 1px solid #DCDCDC;
        line-height: 21px;
        padding: 10px 20px;
        box-sizing: border-box;
        .iconfont {
            color: #FF0000;
        }
        .title {
            color: #333;
            margin: 0 20px;
        }
    }
    .storeSign-btn {
        text-align: center;
        margin: 30px 0;
    }
</style>